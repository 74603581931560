<template>
 <div style='width: 100%; height: 100%; position: relative;'>
   
     <div class='transition' :style='getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='position: relative; display: flex; width: 100%; height: calc(100vh - 190px);'>
		        <div style='float: top;'>
			        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'>{{tr('Placement')}}/Revenue 1.0</div>
			        <br/>
			        <div style='font-size: 9pt;'>{{tr('Placement')}} Start-Date</div>
			        <div style='display: inline-flex; width: 45%;'>
				    <InputDatePick :auto="false" 
	                            @change="dateChanged" v-model="fromDate"/>  
	                            </div>
	                            -
	                <div style='display: inline-flex; width: 45%;'>
	                <InputDatePick :auto="false" @change="dateChanged" v
	                            v-model="untilDate"/>     
	                 </div>  
					 					 
	                <br/>
	                <hr/>
			         <InputSearch v-model="searchTerm" topic='name' @enter="getData()" usage='dashboard'/>
			        <br/>

					<button title="enter new campaign" class='button myCreateButton' :style="getAppStyle()" 
					        @click="$refs.lineitemWizzard.open()"
							><mdicon :size="26" name="new-box" /> <span style="padding-left: 5pt;">{{tr('Placement')}}</span></button> 
					<br/>  
	                <button :title='"reload screen"' class='button myButton' :style="getAppStyle()" @click="getData()"><mdicon :size="18" name="reload" /></button> 
					<button :title='"create excel report"' class='button myButton' :style="getAppStyle()" @click="createExcel()"><mdicon :size="18" name="microsoft-excel" /></button> 
					<button :title='"create excel report (channel-invoice)"' class='button myButton' :style="getAppStyle()" @click="createExcel4Inv()"><mdicon :size="18" name="microsoft-excel" />Inv</button> 
										
												
					<br/>
					<span style='font-size: 8pt'>on click:</span>
					<br/>
					<button :title="'Open/Edit '+tr('Placement')" :class="'button mySelectButton '+(selectedAction===0?'selected':'')"  
				        @click="selectedAction=0"><mdicon :size="16" name="cursor-default-click-outline" /> <span style="padding-left: 5pt;">Open {{tr('Placement')}}</span></button> 			
					<button :title="Open/Edit/Budget" :class="'button mySelectButton '+(selectedAction===1?'selected':'')"  
						@click="selectedAction=1"><mdicon :size="16" name="cursor-default-click-outline" /> <span style="padding-left: 5pt;">Open/Edit/Budget</span></button> 			
											
				</div>
		    	<div style='position:absolute; bottom:50px;'>
		    	<span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	</div>
		    	

		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div v-if="data" class='transition' style='float: left; width: 78%;'> 
	    <!-- HEADER -->
	    
	    <!-- TABLE -->
	    <div v-if="ready" style='width: calc(100%); height: calc(100vh - 110px);'  >
			
			<button v-for="(month,si) in data.months" :key="'stat'+si" 
			    :class="'button myTab'+(activeMonth==month?' myTabActive':'')" :active="activeMonth==month" @click="activate( month)">
				{{month.toUpperCase()}}
			</button>
		
					
	    	<div style='float: top; width: 100%; height: 18pt; overflow-y: scroll;'>
			    <div style='float: top;'>
				    <div v-for="(col,idx) in selectedColumns" :key="'ColH_'+idx" class='floatLeft tableHeader' :style='getColumnStyle(col, idx)'
						@click='setOrderBy(col)'
						>
				       <div class='header' 
				            :style="isDarkMode()?'background-color: #666; color: #fff;':'background-color: #ddd; color: #000;'">
				       		<div class='ltd' :title="col.name"> {{col.name}}<sub>{{getOrderBy(col)}}</sub></div>
				       	</div>
				    </div>
				   
			    </div>  <!-- float: top -->   
			</div> <!-- HEADER --> 
	    <!-- TABLE -->
							
	    <div v-if="ready" style='width: 100%; height: calc(80vH) !important; overflow-y: scroll;' >
			    	<div   style='float: top; width: 100%;height: 100%;' >

			    	    <div v-for="(line,idx) in doOrderBy(limit(data.list, searchTerm))" 
			    	         class='reqLine' :key="'DAT_'+idx" :ref="'DAT_'+idx" :id="'DAT_'+idx"
							 
							 @contextmenu="ctxMenu($event, line, idx)"
			    	         @mouseenter="hoverLine('DAT_'+idx)"
			    	         @mouseleave="leaveLine('DAT_'+idx)"
							 
			    	        >

			    	        <div v-for="(col,idx2) in selectedColumns" :key="'CS_'+idx+'_'+idx2" :class="'floatLeft'"  :style='getColumnStyle(col, idx2)'>
			    	            <div :class="'reqDetail dontWrap '+ col.class"  >
						            <div v-if="col.type==='icon'" :title="col.tooltip" style='width: 100%; height: 100%; padding-top: 0pt !important;'>
						       			<span>
											<button v-for="(s,si) in nextStates" :key="'sk'+si" class="mySwitchButton"
											        :title="'Switch to '+s.label"
											        @click="switchTo(line.placement, s)"><mdicon :size="16" :name="s.icon" /></button>
						       			</span>
						       		</div>

						       		<div v-else :title="line.display[col.id]" :class="'reqDetail2 '+ col.class" :style='getColumnDetailStyle(col, line)' @click="actionOnClick( line)" >
										
						       			{{line.display[col.id]}}
						       		</div>
						       	</div>
						     </div> 
							 	
				    	        
			    	    </div>
			    		
			    	</div> 
		    	</div>  <!-- TABLE -->
    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    <ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData && contextData.data"  slot-scope="{ contextData }">
        
		<ContextMenuItem v-if="contextData.data.id" @clicked="$refs.menu.close(); openModule('Placement', contextData.data.id); ">
        	Edit {{contextData.data.placementName}}
        </ContextMenuItem>
		<ContextMenuSep/>
		<ContextMenuItem @clicked="$refs.menu.close(); openBudgetView(contextData.data); ">
        	View monthly budgets
        </ContextMenuItem>
	
      </template>    
	</ContextMenu>
	<PDFViewer name='pdfView' ref='pdfView' />
    <GConfirm ref='confirm'/>
    
    <GFWEOpenEditor ref='editor'></GFWEOpenEditor>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
	<RevenueView ref='revenueView' 
	       :currentLine = currentLine
	        @placement="p=> save(p)"
	        @close="closeBreakView()">
	</RevenueView>
	<LineItemWizard ref='lineitemWizzard' @reload="getData()"></LineItemWizard>
	
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, opsAPI, reportAPI, dealReportAPI, clientReportAPI, copyWorkflowAPI, placementServiceAPI, setReload, myLocale, showError, formatNumber} from '@/variables.js';
import { setGoBack } from '@/breadCrumb.js';
import { tr } from '@/translate.js';
import { isoDate } from '@/basicTimeFN.js';
import { forceFileDownload} from '@/fileUtil.js';
import { getAppStyle, isDarkMode, initAppMode, getFG, getBG, getBGColor } from '@/AppStyle.js';
import { printTimeMMSS, printTimeHHMM } from '@/basicTimeFN.js';
import {store, restore} from '@/PlacementStatus.js';
import InputTime from '@/components/inputElements/InputTime2';
import InputText from '@/components/inputElements/InputText';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import ContextMenuLabel from '@/components/ContextMenuLabel';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import GSelectMSimple from '@/components/GSelectMSimple'; 
import ProgressBar from '@/components/ProgressBar';
import PDFViewer from '@/components/PDFViewer';
import RevenueView from '@/components/LineItemSiteRevenue';
import GConfirm from '@/components/GConfirm';
import LineItemWizard from '@/components/Ops/LineItemExtRevWizard';
import CopyView from '@/components/misc/CopyView';
import InputSearch from '@/components/inputElements/InputSearch';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { format } from '@/Numbers.js';
var momentTZ = require('moment-timezone');

var timers = [];
var lastMarked = "-";
import JQuery from "jquery";
let $ = JQuery;
export default {
  name: 'NEXT_CopyENTRY_View',
  components : {
   'app-switch': Switch, InputDatePick, PDFViewer, RevenueView,
   ProgressBar, InputSearch, GConfirm, ContextMenu, ContextMenuItem, ContextMenuSep, LineItemWizard
  },
  data () {
    return {
      data: {},
	  types: [],
      fromDate: new Date(),
      untilDate: new Date(),
      showProgressBar: false,
      searchTerm: "",
	  activeMonth: "",
      search: "",
      dealId: 0,
	  selectedAction: 0,
      dealData: {},
      dataSave: {},
      pbTitle: "",
	  pbCall: "",
      pbAction: "",
	  pbId: 0,
	  pbStatusId: 0,
      controlsRight: false,
      ready: false,
	  currentLine: {},
      fontSize: 11,
      widthCalculated: 800,
      tr, getAppStyle, getBGColor, format, isDarkMode,
      orderBy: [],
      p: 1,
      availableColumns: [
	        {id: 10, name: "Name", show: "placementName", type: 'txt', disabled: true, class:'left', w: 8},
			{id: 20, name: "Campaign", show: "campaign", type: 'txt', disabled: true, class:'left', w: 5},
			{id: 30, name: "Deal", show: "deal", type: 'txt', disabled: true, class:'left', w: 5},
			{id: 40, name: "Status", show: "status", type: 'txt', disabled: true, class:'centered', w: 2},
			{id: 50, name: "Type", show: "type", type: 'txt', disabled: true, class:'centered', w: 2},
			{id: 60, name: "Agency", show: "agency", type: 'txt', disabled: true, class:'left', w: 6},
			{id: 70, name: "Client", show: "client", type: 'txt', disabled: true, class:'left', w: 4},
			{id: 75, name: "Reference", show: "ref", type: 'txt', disabled: true, class:'left', w: 4},
			//{id: 80, name: "Product", show: "product", type: 'txt', disabled: true, class:'left', w: 4},
			{id: 90, name: "Budget", show: "budget", type: 'txt', disabled: true, class:'right', w: 3},
			{id: 95, name: 'Sites', show: "sites", type: 'txt', disabled: true, class:'right', w: 2},
			{id: 100, name: "Days", show: "days", type: 'txt', disabled: true, class:'right', w: 2},
			{id: 110, name: "From", show: "fromDate", type: 'txt', disabled: true, class:'centered', w: 3},
			{id: 120, name: "To", show: "untilDate", type: 'txt', disabled: true, class:'centered', w: 3},
			{id: 130, name: "Inv", show: "billParam", type: 'txt', disabled: true, class:'left', w: 2},
			{id: 140, name: "Tax", show: "tax", type: 'txt', disabled: true, class:'left', w: 2}
      ],
      selectedIN: "",
      selectedDefault: [10,20,30,40,50,60,70,75,90,95,100,110,120,130,140],
      selectedSave: [10,20,30,40,50,60,70,75,90,95,100,110,120,130,140],
      selectedDefaultD: [10,20,30,40,50,60,70,75,90,95,100,110,120,130,140],
      selectedDefaultP: [10,20,30,40,50,60,70,75,90,95,100,110,120,130,140],
      selectedDefaultPM: [10,20,30,40,50,60,70,75,90,95,100,110,120,130,140],
      selected: [],
      selectedColumns: [
	        
      ]
    }
  },
  methods: {
	dateChanged()
	{
	    	this.getData();
	},
    action( what, myId, api)
    {
   		this[what](myId, api)
    },
	activate( stat )
	{
		this.activeMonth = stat;
	},
	save( placement)
	{
		let that = this;
		
		HTTP.post( placementServiceAPI+"/savePlacement/"+sessionStorage.tenantId
   				+"/"+sessionStorage.accessPointId
   				+"/"+sessionStorage.unitId
   				+"/"+sessionStorage.userId
   				, placement
   				).then( response => 
			      {
					this.currentLine = {type: 'PLACEMENT', sites: this.data.sites, placement: response.data.placement, info: response.data};
					if( this.data )
					{
						for ( let i in this.data.list)
						{
							
							if ( this.data.list[i].id == response.data.id)
							{
								this.data.list[i] = response.data;
								break;
							}
						}
					}
				  }	).catch(e => {
								    console.log(e)
				            	    showError( that.$toast, "savePlacement", e);
									
				                });
	},
	
	actionOnClick( line)
	{
		if ( this.selectedAction == 0 )
		{
			this.openModule( 'Placement', line.id);
		}
		else if ( this.selectedAction == 1 )
		{
			this.openBudgetView( line);
		}
		else if ( this.selectedAction == 2 )
		{
			this.downloadConf( line.id)
		}
		else if ( this.selectedAction >= 3 )
		{
			this.downloadSpotList( line.id, this.data.reports[this.selectedAction-3])
		}
	},
	openBudgetView( data)
	{
		this.currentLine = {type: 'PLACEMENT', sites: this.data.sites, placement: data.placement, info: data};
		this.$refs.revenueView.open( this.currentLine );
	},
	openModule(what, id)
	{
		this.$refs.editor.open( what, id);
	},
    show( line)
    {
    	alert( JSON.stringify( line.placementIds))
    },
    ctxMenu(event, data, idx) 
    { 
       event.preventDefault();
       this.$refs.menu.open(event, {data: data, row: idx} )
    },
	downloadSpotList( placementId, report )
    {
    	let that = this;
    	HTTP.post( opsAPI+"/downloadSpotList/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+placementId, report)
	        .then( response => 
	        { 
               
               let downloadResponse = response.data;
			   forceFileDownload( downloadResponse.file, downloadResponse.filename);
           		
        	}).catch(e => (showError( that.$toast, "file download error", e)))

    },
	createExcel( ) {
    	this.pbAction = "createExcelInternal";
    	this.pbInfo = "Creating excel";
		this.pbCall = "createExcel4PlacementExtRevenue"
    	this.showProgressBar = true;
    },
	createExcel4Inv( ) {
		this.pbAction = "createExcelInternal";
		this.pbInfo = "Creating channel invoice excel";
		this.pbCall = "createExcel4PlacementExtRevenueChannelInv"
		this.showProgressBar = true;
	},
	createExcelInternal(myId, api)
	{
		let that = this;
		let req = {	 
					fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
					untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
		};
		let inputStruct = { search: this.searchTerm};
		api.post( placementServiceAPI+"/"+this.pbCall+"/"+sessionStorage.tenantId
			+"/"+sessionStorage.accessPointId
			+"/"+sessionStorage.unitId
			+"/"+sessionStorage.userId
			+"/"+req.fromDateISO
			+"/"+req.untilDateISO
			+"/"+myId, inputStruct
			)

		 .then( response => 
         {
			let downloadResponse = response.data;
          
            forceFileDownload( downloadResponse.file, downloadResponse.filename);
			that.showProgressBar = false;
         	//
            
         }).catch(e => {
        	    showError( that.$toast, "get excel data", e);
        	    that.showProgressBar = false;
               
            });
	},
	
	openLine( line)
	{
		if ( line.status === 0 || line.status === 2 || !line.copyId)
		{
			this.$refs.copyWizzard.open(this.data.provider, this.data.languages, this.data.stati, this.data.mediaFamilies, this.data.copyTypes,  line, null, this.searchTerm);
		}
		else 
		{
			this.$refs.copyViewer.open(line);
		}
	},
	getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 21%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 21%; height: calc(100vh - 120px); padding: 2ex;"
    },
    getColumnStyle(col, idx)
    {
    	let wTotal = this.widthCalculated;
        return "width: " + (parseInt(col.w)/wTotal*100) +"%; background-color: "+getBG()+";color: "+getFG();
    },
    getColumnDetailStyle(col, line)
    {
     	return "width: 100%; height: 100%; font-size:"+this.fontSize+"px;";
    },
    doOrderBy(data)
    {
       let that = this;
       
    	data.sort(function(a,b) {
	     	for ( var i in that.orderBy)
	     	{
	     		let iCol = that.orderBy[i];
	     		let v1 = a[ iCol.show ];
	     		let v2 = b[ iCol.show ];
	     		
	     		if ( ! v2 || v1 > v2 )
	     		{
	     			return iCol.sorting;
	     		}
	     		if ( ! v1 || v1 < v2 )
	     		{
	     			return -iCol.sorting;
	     		}
	     	}
	     	return a.index - b.index;
	     });
		return data;
    },
    doSearch( force) {
	  	if ( force || (this.searchTerm))
		{
	    	this.search = this.searchTerm ? this.searchTerm.toLowerCase(): "*";
	    	this.limit( this.data);
		}
		
	  },
	  applySearch( data)
	    {
	    	let filtered = [];
			this.search = this.searchTerm ? this.searchTerm.toLowerCase(): "*";
	    	for (let di in data)
	    	{
	    		let line = data[di];
	    		line.display = [];
				
	    		let match = !this.search || this.search==="*";
	    		for (let ci in this.selectedColumns)
	    		{
	    			let col = this.selectedColumns[ci];
	    			let displayValue = this.print( line, col);
	    			line.display[ col.id ] = displayValue;
	    			if ( displayValue )
	    			{
						//alert( displayValue.toString().toLowerCase() +"//"+this.searchTerm+displayValue.toString().toLowerCase().includes(this.search))
		    			match = (match || (displayValue && displayValue.toString().toLowerCase().includes(this.search)));
	    			}
	    		}
	    		if ( match )
	    		{
	    			filtered.push( line);
	    		}
	    	}
	    	return filtered;
	    },
    limit( data)
    {
        
        let tmp = data.filter( p=>p.start==this.activeMonth)
		
        tmp = this.applySearch( tmp);
		
    	let array = this.doOrderBy( tmp);
    	if ( this.newLine )
    	{
    		array.push( this.newLine);
    	}
    	
    	return array;
    },
    hoverLine(line)
    {
    	
    	if ( isDarkMode())
    	{
    		$('#'+line).children().addClass('reqLineBGDM');
    	}
    	else
    	{
    		$('#'+line).children().addClass('reqLineBG');
    	}
    },
    leaveLine(line)
    {
    	if ( isDarkMode())
    	{
    		$('#'+line).children().removeClass('reqLineBGDM');
    	}
    	else
    	{
    		$('#'+line).children().removeClass('reqLineBG');
    	}
    },
    print( line, col)
    {
       //return spot[ col.show ];
       let xLine = line;
       let val = null;
       if ( col.show==='ref' )
       {
		 val = line.placement.clientReference;
		
   	     return val;
   	   }
	   if ( col.show==='sites' )
	   {
	   	 return (line.sites) ? line.sites.length: 0;
	   }
   	   if ( col.show.endsWith("Ids"))
       {
		   val = xLine[ col.show ].length;
    	   return val;
       } 
	   
       val = xLine[ col.show ];
       
       if ( col.type==='time' )
    	{
    	   return printTimeMMSS(val);
    	}
		
		
       if ( col.type==='perc' )
       {
   	     return (Math.round( 100 * val)/100)+'%';
   	   }

    	if ( ! val )
    	{
    		return "";
    	}
    	
    	if ( col.boolean )
    	{
    		if ( val )
    		{
    			return "X";
    		}
    		return "";
    	}
    	if ( isNaN( val) )
    	{
    		return val?val:"";
    	}
    	try
    	{
    		
    		return val ? formatNumber( val ) : val;
    	}
    	catch(e) {
    		//
    	}
    },
	deleteEntry( id)
	{
		let that = this;
		HTTP.delete( fwAPI+"/delete/"+sessionStorage.tenantId
		       				+"/"+sessionStorage.accessPointId
		       				+"/"+sessionStorage.userId
		       				+"/CopyEntry"
		       				+"/"+id
		       				)
			.then( response => {
				that.getData();
		}).catch(e => {
    	    showError( that.$toast, "get copy data", e);
        });
							                 
	},
	downloadConf(plcm) { 
   	    	this.pbId = plcm;
   	    	this.pbAction = "downloadConfInternal";
   	    	this.pbTitle = "Creating confirmation";
   	  	    this.showProgressBar = true;
    },
	downloadConfInternal( myId, api )
    {
    	let that = this;
		api.post( opsAPI+"/downloadConf/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.pbId+"/1/"+myId)
	        .then( response => 
	        { 
               
               let downloadResponse = response.data;
			   that.showProgressBar = false;
               that.$refs.pdfView.open(downloadResponse.file, downloadResponse.filename)
           		
        	}).catch(e => {that.showProgressBar = false; showError(  that.$toast, "file download error", e)})

    },
    getData( ) {
    	this.pbAction = "getDataInternal";
    	this.pbInfo = "Loading data";
    	this.showProgressBar = true;
    },
    getDataInternal( myId, api ) {
        let that = this;
		
		let req = {	 
					fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
					untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
		};
		let inputStruct = { search: this.searchTerm};
		let dataLoad = new Promise(function (resolve, reject) {
			
       		api.post( placementServiceAPI+"/findPlacementList4ExternalRevenue/"+sessionStorage.tenantId
       				+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId
       				+"/"+req.fromDateISO
       				+"/"+req.untilDateISO
       				+"/"+myId, inputStruct
       				)

        		 .then( response => 
                 {
					
                 	that.data = response.data;
                 	that.dataSave = response.data;
					that.types = response.data.types;
					//alert( JSON.stringify( that.data.sites))
					
					that.data.sites = that.data.sites.map( p=> { return {id: p.id, label: (p.adTax?" ":"")+ p.shortname + " ("+p.costCenter+")", visible: true, adTax: p.adTax}});
									
					that.data.sites.sort( (a,b)=>a.label.localeCompare(b.label))
                 	that.selectedDefault = that.selectedDefaultD;
					
                 	//alert( JSON.stringify( that.data.stati))
                 	that.selected = null;
                 	that.arrangeColumns();
					
                 	that.ready = true;
					
					that.showProgressBar = false;
					if ( !that.activeMonth)
					{
						that.activeMonth = that.data.months[0];
					}
                    resolve (true);     
                    
                 }).catch(e => {
					   console.log(e)
                	    showError( that.$toast, "get copy data", e);
                	    that.showProgressBar = false;
                        reject ();
                    });
	      });
    },
	
	
    goBack()
    {
    	this.data = [];
    	this.selectedDefault = [...this.selectedSave];
    	this.selected = null;
        this.arrangeColumns();
    	for ( let i in this.dataSave)
    	{
    		this.data.push( this.dataSave[i]);
    	}

    },
    getOrderBy( col )
    {
    	for ( var i in this.orderBy)
    	{
    		let iCol = this.orderBy[i];
    		if ( iCol.show === col.show )
    		{
    		    if ( iCol.sorting > 0 )
    		    {
    				return "A"+(parseInt(i)+1);
    			}
    			return "D"+(parseInt(i)+1);
    		}
    	}
    	return "";
    },
   setOrderBy( col )
    {
       if ( col.type === 'icon' || col.type === 'progress')
       {
    	   return;
       }
       var add = true;
    	for ( var i in this.orderBy)
    	{
    		let iCol = this.orderBy[i];
    		if ( iCol.show === col.show )
    		{
    		    if ( col.sorting === 1 )
    		    {
    		    	col.sorting = -1;
    		    }
    		    else
    		    {
    				this.orderBy.splice( i, 1);
    				col.sorting = 0;
    			}
    			add = false;
    			break;
    		}
    	}
    	if ( add )
    	{
    		this.orderBy.push( col );
    		col.sorting = 1;
    	}
    	//this.doOrderBy();
    	this.$forceUpdate();
    	
    },
	arrangeColumns()
	{
		this.availableColumns = this.availableColumns.sort((a,b)=>a.name.localeCompare(b.name));
	    this.selectedColumns = new Array();
	    //alert( JSON.stringify( this.selectedIN))
	    if ( this.selectedIN)
	    {
	    	try
	    	{
	    		this.selected = this.selectedIN.split(',');
	    		//alert( this.selected)
	        } catch (e)
	        {
	        	//alert(JSON.stringify(e))
	        	this.selected = [];
	        }
	    	
	    }
	    //alert( JSON.stringify( this.selected))
	    if ( ! this.selected || ! this.selected.length )
	    {
	    	this.selected = [];
	    	
	    	for ( let i in this.selectedDefault )
			{
				let col = this.availableColumns.find( p=> p.id===this.selectedDefault[i])
				this.selected.push( col.id);
			}
	    }
	    //console.log(JSON.stringify(this.selected))
		for ( let i in this.selected )
		{
			let id = parseInt(this.selected[i]);
			for ( var j in this.availableColumns )
			{
				let column = this.availableColumns[j];
				if ( column.id === id )
				{
					this.selectedColumns.push( column );
					break;
				}
			}
	    }
	    this.calcWidths( false);
	},
	calcWidths( withSaving)
	{
	    let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].id;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].id;
     		}
     	}
		this.widthCalculated = wTot;
		
	},
    storeSettings() 
    {
		let obj = restore();
		if ( !obj )
		{
			obj = {};
		}
		obj.selectedColumns = this.selectedColumns;
		obj.selectedDefault = this.selectedDefault;
		obj.fromDate = this.fromDate;
		obj.untilDate = this.untilDate;
		obj.nextStates = this.nextStates;
		obj.validProvider = this.validProvider;
		obj.searchTerm = this.searchTerm;
		store( obj);
    },

  },
  	
  beforeDestroy() {
	 this.storeSettings();

  },
  created() {
	  	let restoreView = restore();
	  	if ( restoreView && restoreView.data )
	  	{
			this.selectedColumns = restoreView.selectedColumns;
			this.selectedDefault = restoreView.selectedDefault;
			this.fromDate = restoreView.fromDate;
			this.untilDate = restoreView.untilDate;
			this.nextStates = restoreView.nextStates;
			this.searchTerm = restoreView.searchTerm;
			this.validProvider = restoreView.validProvider;
			this.ready = true;
			this.arrangeColumns();
			this.calcWidths();
		
		}
		else
		{
			let today = new Date();
			this.fromDate = new Date();
			
			this.fromDate = new Date( isoDate(today.getFullYear(), 1, 1));
			this.untilDate = new Date( isoDate(today.getFullYear(), 12, 31));
			//this.untilDate.setDate(this.untilDate.getDate() + 36);
			this.fromDate = momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0]
			this.untilDate = momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0]
			this.arrangeColumns();
			this.calcWidths();
			this.getData();
		}	  
		initAppMode();
	    

  },
  watch: {
  	fromDate: function() { this.untilDate = new Date(this.fromDate.getFullYear(), 11, 31); },
  	untilDate: function() {  },
  	
  },
  updated() {
  }
}
</script>
<style lang="scss" scoped>
 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11pt !important; 
  font-weight: bold; 
  width: 100% !important; 
  height: 18pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  }
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  overflow: hidden; 
  text-overflow: ellipsis;
}
.centered {
  justify-content: center;
  text-align: center;
}
.right {
  justify-content: right;
  text-align: right;
}
.left {
  justify-content: left;
  text-align: left;
}
.floatLeft {
    display: inline-flex;
    float: left;
}
.reqLineBG {
  background-color: #e8f0e8 !important; 
}
.reqLineBGDM {
  background-color: #aca !important; 
  color: #000 !important;
}
.ltd {
  padding-left: 1pt;
  padding-right: 1pt;
  overflow-x: hidden;
  font-size: 11px;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.reqDetail { 
  display: flex;
  align-items: center;
  font-size: 8pt;  
  width: 100% !important; 
  height: 20pt; 
  padding-left: 2pt;
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  overflow: hidden;
  cursor:pointer;
  //background-color: #aaa; 
}
.reqDetail2 { 
  padding-top: 3pt;
  cursor:pointer;
  //background-color: #aaa; 
}
.reqDetailNB { 
  display: flex;
  align-items: center;
  font-size: 8pt;  
  width: 100% !important; 
  height: 20pt; 
  overflow: hidden;
  cursor:pointer;
  //background-color: #aaa; 
}
.mySwitchButton {
	width: 30% !important;
    padding: 0px 0px;
    outline: none;
	justify-content: left;
    border-radius: 4px 4px 4px 4px;
    height: 20pt;
    font-size: 7pt;
    background-color: #fffaff;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 0pt;
    margin-right: 0pt;
    margin-bottom: 0pt;
}
.myTab {
	width: 80pt;
    padding: 5px 11px;
    outline: none;
	font-weight: bold;
    border-radius: 4px 12px 0 0;
    height: 20pt;
    font-size: 7pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 0pt;
    margin-bottom: 0pt;
}
.myTabActive {
	//width: 18%;
    padding: 3px 10px;
    background-color: #aaf;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
} 
.mySelectButton {
	width: 60% !important;
    padding: 5px 1px;
    outline: none;
	justify-content: left;
    border-radius: 4px 4px 4px 4px;
    height: 20pt;
    font-size: 7pt;
    background-color: #f8f8ff;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 0pt;
    margin-bottom: 0pt;
}
.selected {
	width: 62% !important;
	background-color: rgb(0, 128, 128);
	color: rgb(0, 255, 255);
}
</style>

